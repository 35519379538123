import React, {useEffect, useRef, useState} from 'react';

const useClickOutside = () => {
  let elRef = useRef<any>(null);
  const [bool, setbool] = useState<any>(false);

  const handleOutside = (e: {target: any}) => {
    if (elRef && elRef.current) {
      if (elRef.current.contains(e.target)) {
        return;
      }
      setbool(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutside);
    return () => {
      document.removeEventListener('click', handleOutside);
    };
  }, []);

  return [bool, elRef, setbool];
};

export default useClickOutside;
