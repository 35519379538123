import React, {FC, FormEvent} from 'react';
import './styles.scss';

interface IAppProps {
  value: string;
  onChange: any;
  placeholder?: string;
  name?: string;
  rows?: number;
  className?: string;
  onBlur?: any;
  onFocus?: any;
  disableResize?: boolean;
  error?: boolean;
  id?: string;
}

const TextArea: FC<IAppProps> = ({
  value,
  onChange,
  placeholder,
  name,
  rows,
  className,
  onBlur,
  onFocus,
  disableResize,
  error,
  id,
}) => {
  const fieldChangeIndex = (e: FormEvent) => {
    onChange(e);
  };

  return (
    <>
      <textarea
        id={id ? id : ''}
        className={`textarea ${className ? className : ''} ${disableResize ? 'disable-resize' : ''} ${
          error ? 'error-border' : ''
        }`}
        name={name}
        placeholder={placeholder}
        value={value}
        rows={rows || 6}
        onChange={fieldChangeIndex}
        onBlur={() => onBlur && onBlur()}
        onFocus={() => onFocus && onFocus()}
        maxLength={2000}
      />
    </>
  );
};

export default TextArea;
