import React, {FC} from 'react';

import './styles.scss';

interface IProps {
  color: string;
  img: string;
  title: string;
  text: string;
}
const PageHero: FC<IProps> = ({color, img, title, text}) => {
  return (
    <div className="page-hero">
      <div className="layers-container">
        <div className="layer-1">
          <div className="hero-img d-md-none">
            <img src={img} alt="page-hero" />
          </div>
          <div className="hero-background"></div>
          <div className="hero-img d-none d-md-block">
            <img src={img} alt="page-hero" />
          </div>
        </div>

        <div className="layer-2">
          <div className="header-title" style={{backgroundColor: color}}>
            <div className="header-title-wrapper">
              <h1>{title}</h1>
              <h2> {text}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHero;
