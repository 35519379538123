import {Link} from 'gatsby';
import React, {useEffect, useState} from 'react';
import footerLogo from '../../../assets/footer-logo.svg';
import {crunchbase, facebook, instagram, linkedin, twitter, youtube} from '../../../assets//svg-icons';
import './styles.scss';
import {Modal} from '../../modal';
import {closeIcon} from '../../../assets//svg-icons';
import ContactUs from '../../shared-components/contact-us';

const Footer = () => {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    global &&
      global.window &&
      global.window.location &&
      global.window.location.hash &&
      global.window.location.hash === '#contact-us' &&
      setOpenModal(true);
  }, []);

  const handleContactUsClick = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="footer-top">
          <div className="logo-buttom d-flex">
            <Link to={'/'} className="footer-logo">
              <img src={footerLogo} alt="Hexact, making sense of data for actionable insights"></img>
            </Link>
          </div>

          <div className="footer-nav-mobile d-lg-none">
            <Link to="/terms-of-use">Terms of Service</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/cookie-policy">Cookie Policy</Link>
            <span className="contact-us" onClick={handleContactUsClick}>
              Contact us
            </span>
          </div>
          <div className="social-links-wrapper">
            <a aria-label="crunchbase" href="https://www.crunchbase.com/organization/hexact" target="_blank" rel='nofollow noopener'>
              {crunchbase}
            </a>
            <a aria-label="linkedin" href="https://www.linkedin.com/company/hexactinc" target="_blank" rel='nofollow noopener'>
              {linkedin}
            </a>
            <a aria-label="facebook" href="https://www.facebook.com/hexactinc/" target="_blank" rel='nofollow noopener'>
              {facebook}
            </a>
            <a aria-label="instagram" href="https://www.instagram.com/hexacthq/" target="_blank" rel='nofollow noopener'>
              {instagram}
            </a>
            <a aria-label="twitter" href="https://twitter.com/hexactinc" target="_blank" rel='nofollow noopener'>
              {twitter}
            </a>
            <a aria-label="youtube" href="https://www.youtube.com/c/HexactInc" target="_blank" rel='nofollow noopener'>
              {youtube}
            </a>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="footer-bottom-left">
            <div id="date">
              <span> &copy; {new Date().getFullYear()} Hexact, Inc. </span>
              <span>1250 E. Hallandale Beach Blvd., Unit 808, </span>
              <span>Hallandale Beach, Florida 33009</span>
            </div>
          </div>

          <div className="footer-bottom-right d-none d-lg-block">
            <Link to="/terms-of-use">Terms of Service</Link>
            <span></span>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <span></span>
            <Link to="/cookie-policy">Cookie Policy</Link>
            <span></span>
            <span className="contact-us" onClick={handleContactUsClick}>
              Contact us
            </span>
          </div>
        </div>
      </div>
      {openModal && (
        <Modal isOpen={openModal} modalToggle={() => {}} modalFooter={<></>} className="contacts-modal">
          <span className="position-absolute close-icon" onClick={handleCloseModal}>
            {closeIcon}
          </span>
          <ContactUs closeModal={handleCloseModal} />
        </Modal>
      )}
    </footer>
  );
};
export default Footer;
