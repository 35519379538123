import React, {ReactNode, FC} from 'react';
import Header from './header';
import Footer from './footer';
import PageHero from './pagehero';
import CookieConsentPopup from '../cookies-popup';

import './styles.scss';

//markup

interface LayoutProps {
  children: ReactNode;
  pageheroText?: string;
  pageheroTitle?: any;
  pageheroImg?: string;
  pageheroColor?: string;
  className?: string;
  startenginePage?: boolean;
}

const Layout: FC<LayoutProps> = ({
  children,
  pageheroText,
  pageheroTitle,
  pageheroImg,
  pageheroColor,
  className,
  startenginePage,
}) => {
  return (
    <div className={`page-container ${className ? className : ''}`}>
      <div className="content-wrap">
        <Header startenginePage={startenginePage} />
        {pageheroText && pageheroTitle && pageheroImg && pageheroColor && (
          <PageHero text={pageheroText} title={pageheroTitle} img={pageheroImg} color={pageheroColor} />
        )}
        <div className="content-wrapper">{children}</div>
      </div>
      <CookieConsentPopup />
      <Footer />
    </div>
  );
};

export default Layout;
