import React, {FC, ReactElement} from 'react';
import {arrowDownIcon} from '../../../assets/svg-icons';
import useClickOutside from '../../../hooks/useClickOutside';
import './styles.scss';

export interface SelectTypes {
  value?: string | number | any | {label?: string; value: string};
  options: any;
  children?: any;
  onChange: (arg0: string | any) => void;
  width?: any;
  disabled?: boolean;
  manySelect?: boolean;
  className?: string;
  placeHolder?: string;
  bottom?: boolean;
  icon?: ReactElement;
  showLeft?: boolean;
  showRight?: boolean;
  empty?: boolean;
}

const Select: FC<SelectTypes> = ({
  value,
  options,
  onChange,
  children,
  disabled,
  manySelect,
  className,
  placeHolder,
  icon,
  showLeft,
  showRight,
  empty,
}) => {
  const [showOptions, selectRef, setShowOptions] = useClickOutside();
  return (
    <div
      className={`custom-universal-select ${className ? className : ''} ${
        showLeft ? 'show-left' : showRight ? 'show-right' : ''
      }`}
      ref={selectRef}
    >
      {showLeft || showRight ? (
        <span onClick={() => !disabled && setShowOptions(!showOptions)}>{icon}</span>
      ) : (
        <button
          className={`select ${showOptions ? 'border-color' : ''} ${empty ? 'border-empty' : ''}`}
          disabled={disabled}
          onClick={() => !disabled && setShowOptions(!showOptions)}
        >
          {placeHolder && !value ? (
            <span>{placeHolder}</span>
          ) : (
            <>
              {
                <span
                  className={`selected-value noselect ${
                    value.label === 'Select' || value.label === 'Please select one' ? 'text-secondary' : ''
                  }`}
                >
                  {typeof value === 'string' ? value : value && value.label}
                </span>
              }
              {icon ? icon : <span className="arrow-icon">{arrowDownIcon}</span>}
            </>
          )}
        </button>
      )}
      {showOptions && (
        <div className={`options`}>
          {children
            ? children
            : options &&
              options.map((item: any, index: number) => (
                <p
                  className={`option ${item.value}
                  ${
                    value === item.label ||
                    (manySelect && value && value.find((v: any) => v === item.value)) ||
                    (value === 'all' && item.value === '') ||
                    (typeof value !== 'string' && value && value.value === item.value)
                      ? !manySelect
                        ? ''
                        : 'selected'
                      : ''
                  } ${value.value === item.value ? 'active' : ''}`}
                  key={item.label}
                  onClick={() => {
                    onChange(item);
                    !manySelect && setShowOptions(false);
                  }}
                >
                  {item.label}
                </p>
              ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(Select);
