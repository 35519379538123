import {Link, navigate} from 'gatsby';
import React, {FC, useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Modal} from '../../modal';
import Button from '../../shared-components/button';

import {closeIcon} from '../../../assets/svg-icons';
import backgroun1 from '../../../assets/start-engine/background-1.svg';
import backgroun2 from '../../../assets/start-engine/background-2.svg';
import promoText from '../../../assets/start-engine/promo-text.svg';
import mobileBanner from '../../../assets/start-engine/banner-mobile.svg';
import popupMobile from '../../../assets/start-engine/popup-mobile.svg';
import popupDesktop from '../../../assets/start-engine/popup-desktop.svg';

import './styles.scss';
import Counter from '../../countdown';
import {
  getRawCookie,
  getShowDemoModalonExit,
  getShowModalonExit,
  openCalendly,
  setShowDemoModalonExit,
} from '../../../helpers';

const Header: FC<{startenginePage?: boolean}> = ({startenginePage}) => {
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const [navBarFixClassName, setNavBarFixClassName] = useState('');
  // const [showInvestPopup, setShowInverstPopup] = useState(false);
  // const [validDate, setValidDate] = useState(false);

  const handleToggler = () => {
    setShowHeaderMenu(!showHeaderMenu);
  };

  const handleDetectPosition = (e: any) => {
    if (e.clientY < 20 && getShowDemoModalonExit() !== 'false' && getRawCookie('cookie_preference')) {
      setShowDemoModalonExit('false');
      openCalendly();
    }
  };

  const pathname = global && global.window && global.window.location && global.window.location.pathname;

  useEffect(() => {
    global.document && global.document.addEventListener('mousemove', handleDetectPosition);
    return () => {
      global.document.removeEventListener('mousemove', handleDetectPosition);
    };
  }, []);

  // useEffect(() => {
  //   // Setting document referrer
  //   const referrer =
  //     typeof document !== 'undefined' &&
  //     document &&
  //     document.referrer &&
  //     document.referrer.indexOf('https://hexact.io') === -1 &&
  //     document.referrer;
  //   if (referrer && getRawCookie('document_referrer')) {
  //     removeDocumentReferrer();
  //   }
  //   referrer && setDocumentReferrer(referrer);
  // }, []);

  const handleScrollEvent = () => {
    const top: number = document.documentElement.scrollTop || document.body.scrollTop;
    if (top > 10 && navBarFixClassName !== 'navbar_fixed') {
      setNavBarFixClassName('navbar_fixed');
    } else if (top <= 10 && navBarFixClassName === 'navbar_fixed') {
      setNavBarFixClassName('');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [navBarFixClassName]);

  // useEffect(() => {
  //   const difference = +new Date('Apr 01 2023 16:00:00 GMT') - +new Date(new Date().toUTCString());
  //   if (difference > 0) {
  //     setValidDate(true);
  //   } else {
  //     setValidDate(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     const show = localStorage.getItem('become-investor-2');
  //     if (!show && window?.location?.href && !window.location.href.includes('/startengine/')) {
  //       setShowInverstPopup(true);
  //     } else {
  //       setShowInverstPopup(false);
  //     }
  //   }, 5000);
  // }, []);

  const isMobile = useMediaQuery({maxWidth: 991});

  return (
    <header className={`header ${navBarFixClassName}`}>
      {/* <div
        className="w-100 position-relative d-none d-lg-flex"
        style={{background: '#F4C70C', cursor: 'pointer', height: '60px'}}
        onClick={() => window.open('https://www.startengine.com/offering/hexact?utm_source=hexact&utm_medium=hellobar')}
      >
        <img src={backgroun1} alt="" className="illustration1 d-none d-xl-flex" />
        <div className="w-100 d-flex" style={{zIndex: 100, maxHeight: '60px', justifyContent: 'center'}}>
          <img
            src={promoText}
            alt="Become an investor in Hexact"
            className="my-auto ml-auto promo-text"
            style={{marginRight: '50px'}}
          />
          <div className="my-auto ml-0 mr-auto">
            <Counter date="May 01 2023 07:00:00 GMT" title="DAYS LEFT TO INVEST" />
          </div>
        </div>
        <img src={backgroun2} alt="" className="illustration2 d-none d-xl-flex" />
      </div> */}
      {/* <div
        className="w-100 position-relative d-flex d-lg-none"
        style={{background: '#FFC500', cursor: 'pointer'}}
        onClick={() =>
          startenginePage
            ? window.open('https://www.startengine.com/offering/hexact')
            : navigate('/startengine/?utm_source=hexact&utm_medium=hellobar')
        }
      >
        <img
          src={mobileBanner}
          alt="Become an investor in Hexact"
          className="m-auto"
          style={{maxHeight: '150px', height: 'auto', maxWidth: '100%'}}
          width={290}
          height={150}
        />
        <div className="m-auto countdown-absolute">
          <Counter date="May 01 2023 07:00:00 GMT" title="DAYS LEFT TO INVEST" />
        </div>
      </div> */}
      <div className="container">
        <div className="logo-top">
          <Link to="/">
            <img
              src="https://hexact.io/img/hexact-logo.svg"
              alt="Hexact, making sense of data for actionable insights"
            ></img>
          </Link>
        </div>
        <div className="menu-top" id="navBarSupportedContent">
          <nav className="desktop-nav">
            <div className="nav-item">
              <Link to="/" className={`${pathname === '/' ? 'active' : ''}`}>
                Home
              </Link>
            </div>
            <div className="nav-item">
              <Link to="/products" className={`${pathname && pathname.includes('/products') ? 'active' : ''}`}>
                Products
              </Link>
            </div>
            {/* <div className="nav-item">
              <Link to="/leadership" className={`${pathname && pathname.includes('/leadership') ? 'active' : ''}`}>
                Leadership
              </Link>
            </div> */}
            <div className="nav-item">
              <Link
                to="/for-investors/"
                className={`${pathname && pathname.includes('/for-investors') ? 'active' : ''}`}
              >
                For Investors
              </Link>
            </div>
            {/* <div className="nav-item">
              <Link to="/careers" className={`${pathname && pathname.includes('/careers') ? 'active' : ''}`}>
                Careers
              </Link>
            </div> */}
            <div className="nav-item">
              <a href="https://newsletter.hexact.io/" target="_blank" className={`${pathname && pathname.includes('/news') ? 'active' : ''}`}>
                Newsroom
              </a>
            </div>
            <div className="nav-item">
              <Button btnText="Request a demo" onClick={openCalendly} width="160" success />
            </div>
          </nav>
          <button
            className={`navbar-toggler ${showHeaderMenu ? '' : 'collapsed'}`}
            onClick={handleToggler}
            type="button"
            data-testid="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="menu_toggle">
              <span className="hamburger">
                <span />
                <span />
                <span />
              </span>
              <span className="hamburger-cross">
                <span />
                <span />
              </span>
            </span>
          </button>
        </div>{' '}
      </div>{' '}
      <div className={`${showHeaderMenu ? 'show' : 'hide'} mobile-header-menu`}>
        <nav id="mobile-header-menu">
          <Link to="/">Home</Link>
          <Link to="/products">Products</Link>
          {/* <Link to="/leadership">Leadership</Link> */}
          <Link to="/for-investors">For Investors</Link>
          {/* <Link to="/careers">Careers</Link> */}
          <a href="https://newsletter.hexact.io/" target="_blank">Newsroom</a>
          <Button btnText="Request a demo" onClick={openCalendly} width="160" success className='sm' />
        </nav>
      </div>
      {/* {showInvestPopup && validDate ? (
        <Modal isOpen={showInvestPopup} modalToggle={() => {}} modalFooter={<></>} className="banner-modal">
          <span
            className="position-absolute close-icon"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShowInverstPopup(false);
              localStorage.setItem('become-investor-2', 'true');
            }}
          >
            {closeIcon}
          </span>
          <img
            src={isMobile ? popupMobile : popupDesktop}
            alt="Become an investor in Hexact"
            onClick={() => navigate('/startengine/?utm_source=hexact&utm_medium=pop-up')}
          />
          <div className="popup-counter">
            <Counter date="Apr 01 2023 16:00:00 GMT" className="popup-countdown" />
          </div>
        </Modal>
      ) : null} */}
    </header>
  );
};

export default Header;
