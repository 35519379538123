import React, {useState, FC} from 'react';
import Input from '../input';
import TextArea from '../textarea';
import Button from '../button';
import Select from '../select';
import './styles.scss';
import {checkedIcon} from '../../../assets/svg-icons';
import {isValidEmail} from '../../../helpers';

const options = [
  {label: 'General enquiries', value: 'support@hexact.io'},
  {label: 'Questions or troubleshooting', value: 'support@hexact.io'},
  {label: 'All marketing enquiries and collaborations', value: 'support@hexact.io'},
  {label: 'Press', value: 'support@hexact.io'},
];

const ContactUs: FC<{closeModal: () => void}> = ({closeModal}) => {
  const [formFields, setFormFields] = useState({firstName: '', lastName: '', email: '', comment: '', emailTo: ''});
  const [error, setError] = useState('');
  const [required, setRequired] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [successes, setSuccesses] = useState(false);
  const [emailTo, setEmailTo] = useState({label: 'Please select a department', value: ''});

  const handleChangeFields = (field: string, value: string) => {
    setError('');
    setFormFields({...formFields, [field]: value});
  };
  const handleClick = () => {
    if (requestLoading) return;

    const isComplete = Object.values(formFields).filter((item: string) => !item).length && !!emailTo.value.trim();
    if (!isComplete) {
      if (!isValidEmail(formFields.email.trim())) {
        setError('Email is not valid');
        setRequired(true);
        return;
      }
      if (!emailTo.value.trim()) {
        setError('Please select a department');
        setRequired(true);
        return;
      }
    } else {
      setRequired(false);
      getExistingResult();
    }
  };
  const getExistingResult = async () => {
    setRequestLoading(true);
    try {
      const result = await fetch(`https://api.hexometer.com/v2/ql`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `mutation {
            contactFormSubmit (
                emailTo: "${emailTo.value}",
                name: "${formFields.firstName + ' ' + formFields.lastName}",
                email: "${formFields.email.trim()}",
                subject: "Hexact: contact-us",
                productType: "Hexact",
                message: ${JSON.stringify(formFields.comment)})
                {
                    error
                    message
                }
            }`,
        }),
      });
      await result
        .json()
        .then(({data}) => {
          if (data && data.contactFormSubmit) {
            if (data.contactFormSubmit.error) {
              setError(
                data.contactFormSubmit.message[0] +
                  data.contactFormSubmit.message
                    .replaceAll('_', ' ')
                    .toLowerCase()
                    .slice(1),
              );
            } else {
              setFormFields({firstName: '', lastName: '', email: '', comment: '', emailTo: ''});
              setSuccesses(true);
            }
          }
          setRequestLoading(false);
        })
        .catch(() => {
          setRequestLoading(false);
          setError('Something went wrong');
        });
    } catch (e) {
      setRequestLoading(false);
      setError('Something went wrong');
    }
  };
  return (
    <>
      {!successes ? (
        <div className="contact-us-page mx-auto">
          <h2 className="m-0 text-center">
            Choose the department you want to contact and we will get back to you soon.
          </h2>
          <div className="form-block row mx-0">
            <div className="col-12 col-md-6">
              <label htmlFor="firstName">
                First name <span>*</span>
              </label>
              <Input
                name="firstName"
                id="firstName"
                value={formFields.firstName}
                onChange={(e: {target: {value: string}}) => handleChangeFields('firstName', e.target.value)}
                placeholder="First name"
                error={!formFields.firstName.trim() && required ? 'error' : ''}
              />
            </div>
            <div className="col-12 col-md-6">
              <label htmlFor="lastName">
                Last name <span>*</span>
              </label>
              <Input
                name="lastName"
                id="lastName"
                value={formFields.lastName}
                onChange={(e: {target: {value: string}}) => handleChangeFields('lastName', e.target.value)}
                placeholder="Last name"
                error={!formFields.lastName.trim() && required ? 'error' : ''}
              />
            </div>
            <div className="col-12">
              <label htmlFor="email">
                Your email <span>*</span>
              </label>
              <Input
                className="email-input"
                name="email"
                type="email"
                id="email"
                value={formFields.email}
                onChange={(e: {target: {value: string}}) => handleChangeFields('email', e.target.value)}
                placeholder="example@gmail.com"
                error={required && (!formFields.email.trim() || !isValidEmail(formFields.email)) ? 'error' : ''}
              />
              {/* {required && (!formFields.email || !isValidEmail(formFields.email)) && (
                <div className="error-text">Email is not valid</div>
              )} */}
            </div>
            <div className="col-12">
              <label htmlFor="department">
                Contact department <span>*</span>
              </label>
              <Select
                options={options}
                value={emailTo}
                onChange={(val: any) => {
                  setEmailTo(val);
                }}
                className={!emailTo.value ? 'disabled-text' : ''}
                placeHolder="Please select a department"
                empty={!!error && !emailTo.value.trim()}
              />
            </div>
            <div className="col-12">
              <label htmlFor="comment">
                Your comment <span>*</span>
              </label>
              <TextArea
                value={formFields.comment}
                onChange={(e: {target: {value: string}}) => handleChangeFields('comment', e.target.value)}
                placeholder="Your comment"
                rows={4}
                disableResize
                error={!formFields.comment.trim() && required}
              />
            </div>
            <div className="position-relative col-12">{error && <div className="error-text">{error}</div>}</div>
            <div className="col-12 text-center">
              <Button btnText="Send message" width="230" onClick={handleClick} loading={requestLoading} />
            </div>
          </div>
        </div>
      ) : (
        <div className="px-4 py-5 text-center">
          <span className="checked-icon">{checkedIcon}</span>
          <p className="pt-4 m-0 modal-text">Your message has been successfully sent!</p>
          <p className="p-0 m-0 modal-text">Thank you!</p>
          <div className="col-12 text-center mt-4">
            <Button btnText="OK" width="230" onClick={closeModal} />
          </div>
        </div>
      )}
    </>
  );
};

export default ContactUs;
