import React, {FC} from 'react';
import './style.scss';
export interface InputTypes {
  type?: string;
  value?: string | number;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  ref?: any;
  label?: string;
  className?: string;
  onChange: (e: any) => any;
  onFocus?: (e: any) => any;
  onBlur?: (e: any) => any;
  onKeyDown?: (e: any) => any;
  onKeyUp?: (e: any) => any;
  id?: string;
  name?: string;
  defaultValue?: string;
  dataUrl?: boolean;
  noBorders?: boolean;
  autocomplete?: boolean;
  maxLength?: number;
  min?: string;
  max?: string;
  autoFocus?: boolean;
}
const Input: FC<InputTypes> = ({
  type,
  value,
  placeholder,
  disabled,
  required,
  error,
  ref,
  label,
  className,
  onChange,
  onBlur,
  onFocus,
  onKeyUp,
  onKeyDown,
  id,
  name,
  defaultValue,
  dataUrl,
  noBorders,
  autocomplete,
  maxLength,
  min,
  max,
  autoFocus,
}) => {
  const handleKeyDown = (e: any) => {
    if (type === 'number' && (e.key === 'e' || e.key === 'E')) e.preventDefault();
    onKeyDown && onKeyDown(e);
  };
  return (
    <div className={`custom-input ${noBorders ? 'no-borders' : ''}`}>
      {label && (
        <label className="label" htmlFor={label}>
          {label}
        </label>
      )}
      <div className="input-wrapper">
        <input
          data-url={dataUrl}
          id={id}
          ref={ref ? ref : null}
          type={type ? type : 'text'}
          placeholder={placeholder || ''}
          value={value}
          disabled={disabled ? true : false}
          required={required ? required : false}
          className={`input-field ${error ? 'error-border' : ''}  ${className || ''}`}
          onChange={e => onChange(e)}
          onBlur={e => onBlur && onBlur(e)}
          onFocus={e => onFocus && onFocus(e)}
          onKeyUp={e => onKeyUp && onKeyUp(e)}
          onKeyDown={handleKeyDown}
          name={name}
          defaultValue={defaultValue}
          autoComplete={autocomplete ? 'on' : 'off'}
          maxLength={maxLength ? maxLength : 320}
          min={min}
          max={max}
          autoFocus={autoFocus}
        />
      </div>
    </div>
  );
};

export default Input;
