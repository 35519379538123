import Cookies from 'js-cookie';

const CookieDomains = ['localhost', '.hexact.io'];

export const getRawCookie = (name: string): string | undefined => Cookies.get(name);
export const removeDocumentReferrer = () => {
  CookieDomains.map(domain => {
    Cookies.remove('document_referrer', {
      expires: 30,
      domain: domain,
    });
  });
};

export const setDocumentReferrer = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('document_referrer', value, {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeCookiePolicy = () => {
  CookieDomains.map(domain => {
    Cookies.remove('cookie_preference', {
      expires: 365,
      domain: domain,
    });
  });
};

export const setCookiePolicy = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('cookie_preference', value, {
      expires: 365,
      domain: domain,
    });
  });
};

export const isValidUrl = (url: string) =>
  /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9:%._\/+~#=?]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\/+.~#?&//=?]*)/g.test(url);
export const isValidEmail = (mail: string): boolean =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    mail,
  );

export const isValidPhoneNumber = (val: string): boolean => /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(val);

export const isValidFirstName = (firstName: string): boolean => /(?!^\d+$)^.+$/g.test(firstName);
export const isValidLastName = (lastName: string): boolean => /(?!^\d+$)^.+$/g.test(lastName);

export const isValidDomain = (domain: string) =>
  /^(?:[a-z0-9:%._\/+~#=?](?:[a-z0-9-:%._\/+~#=?]{0,61}[a-z0-9:%._\/+~#=?])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
    domain,
  );
export const validURL = (str: string) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export const openCalendly = () => {
  //@ts-ignore
  global.window &&
    //@ts-ignore
    window.Calendly &&
    //@ts-ignore
    window.Calendly.initPopupWidget({url: 'https://calendly.com/hexact/hexomatic-demo?hide_gdpr_banner=1'});
  return false;
};

export const setShowDemoModalonExit = (show: string) => localStorage.setItem('show-demo-modal-onexit', show);
export const getShowDemoModalonExit = (): string => localStorage.getItem('show-demo-modal-onexit') || 'true';
export const removeShowDemoModalonExit = () => localStorage.removeItem('show-demo-modal-onexit');

export const setShowModalonExit = (show: string) => localStorage.setItem('show-modal-onexit', show);
export const getShowModalonExit = (): string => localStorage.getItem('show-modal-onexit') || 'true';
export const removeShowModalonExit = () => localStorage.removeItem('show-modal-onexit');
